.powered-by-container{
  position: fixed;
  bottom: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .powered-by-container{
    display: none;
  }

}

.app-name{
  margin: 3px;
  padding: 0;
  font-size: 18px;
}

.powered-by{
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
  font-size: 8px;
}

.cadre-logo{
  height: 20px;
}
